import "../../App.css";

function PrivacyPolicy() {
  return (
    <div class="policy-wrapper">
      <h1 class="policy-header">Privacy Policy</h1>
      <a class="policy-link" href="/">
        &lt;&lt; Return to home page
      </a>

      <h2>A. Definitions</h2>
      <p>
        The following definitions will be referred to throughout this document
        and mean the following:
      </p>
      <ul>
        <li>
          '<span class="highlighted-word">Offerings</span>' - Refers to any
          products or services that has been developed, coded, offered, deployed
          by CROWZFX LTD. This includes, but not limited to: websites, services,
          platforms, and mobile applications.
        </li>
        <li>
          '<span class="highlighted-word">Company</span>'/'
          <span class="highlighted-word">Business</span>'/'
          <span class="highlighted-word">CROWZFX</span>' - Refers to the
          business of CROWZFX LTD and any trading names, services and/or
          products that is owned and offered.
        </li>
      </ul>

      <p>
        At <span class="highlighted-word">CROWZFX</span>, one of our main
        priorities is the privacy of our visitors. This Privacy Policy document
        contains the types of information that is collected and recorded by{" "}
        <span class="highlighted-word">CROWZFX</span> and how we use it,
        throughout your use of this website and any of our{" "}
        <span class="highlighted-word">offerings</span>.
      </p>

      <p>
        If you have additional questions or require more information about our
        Privacy Policy, do not hesitate to contact us at{" "}
        <span class="highlighted-word">
          <a href="mailto:contact@crowzfx.co.uk">contact@crowzfx.co.uk</a>
        </span>
        .
      </p>

      <h2>1. Logging, Monitoring and Log Files</h2>
      <p>
        <span class="highlighted-word">CROWZFX</span> follows typical IT
        procedures and implementations of monitoring and logging the use of our{" "}
        <span class="highlighted-word">offerings</span>; this can include but
        not limited too the use of log files. These files log visitors and other
        metadata when they visit, utilise and access said{" "}
        <span class="highlighted-word">offerings</span>.
      </p>

      <p>
        All hosting companies do this and a part of hosting services' analytics.
        The information collected by log files include internet protocol (IP)
        addresses, browser type, Internet Service Provider (ISP), date and
        timestamp, referring/exit pages, and possibly the number of clicks.
        These are not linked to any information that is personally identifiable.
        The purpose of the information is for analysing trends, administering,
        tracking users' movement, threat detection and mitigation as well and
        gathering demographic information in any of the{" "}
        <span class="highlighted-word">offerings</span>.
      </p>

      <h2>2. Cookies and Web Beacons</h2>
      <p>
        Like any other website, <span class="highlighted-word">CROWZFX</span>{" "}
        uses 'cookies'. These cookies are used to store information including
        visitors' preferences, and the pages on the website that the visitor
        accessed or visited. The information is used to optimize the users'
        experience by customizing our content based on visitors' devices,
        browser type(s) and/or other information. For a full breakdown see the{" "}
        <a href="/policies/cookie">cookie policy</a>.
      </p>

      <h2>3. Our Advertising Partners</h2>
      <p>
        Some advertisers on our site may use cookies and/or web beacons. Our
        advertising partners are listed below. Each of our advertising partners
        have their own Privacy Policy for user data. For easier access, we
        hyperlinked to their Privacy Policies below.
      </p>
      <ul>
        <li>
          <p>
            <a href="https://policies.google.com/technologies/ads">
              Google Ads Policy
            </a>
          </p>
        </li>
      </ul>

      <h2>4. Privacy Policies</h2>
      <p>
        You may consult this list to find the Privacy Policy for each of the
        advertising partners of <span class="highlighted-word">CROWZFX</span>.
      </p>

      <p>
        Third-party ad servers or ad networks uses technologies like cookies,
        JavaScript, or Web Beacons that are used in their respective
        advertisements and links that appear on{" "}
        <span class="highlighted-word">CROWZFX</span>, which are sent directly
        to users' devices. They automatically receive your IP address when this
        occurs. These technologies are used to measure the effectiveness of
        their advertising campaigns and/or to personalize the advertising
        content that you see on websites that you visit.
      </p>

      <p>
        Note that <span class="highlighted-word">CROWZFX</span> has no access to
        or control over these cookies that are used by third-party advertisers.
      </p>

      <h2>5. Third Party Privacy Policies</h2>
      <p>
        <span class="highlighted-word">CROWZFX's</span> Privacy Policy does not
        apply to other advertisers or websites. Thus, we are advising you to
        consult the respective Privacy Policies of these third-party ad servers
        for more detailed information. It may include their practices and
        instructions about how to opt-out of certain options.
      </p>
      <p>
        You can choose to disable cookies through your individual browser
        options. To know more detailed information about cookie management with
        specific web browsers, it can be found at the browsers' respective
        websites.
      </p>

      <h2>6. Children's Information</h2>
      <p>
        Another part of our priority is adding protection for children while
        using the internet. We encourage parents and guardians to observe,
        participate in, and/or monitor and guide their online activity.
      </p>
      <p>
        <span class="highlighted-word">CROWZFX</span> does not knowingly collect
        any Personal Identifiable Information from children under the age of 13.
        If you think that your child provided this kind of information on our
        website, we strongly encourage you to contact us immediately and we will
        do our best efforts to promptly remove such information from our
        records.
      </p>

      <h2>7. Relevancy</h2>
      <p>
        This Privacy Policy applies only to all{" "}
        <span class="highlighted-word">CROWZFX</span> activities including any
        information collected offline or via channels other than digitally and
        is valid for all users of our{" "}
        <span class="highlighted-word">offerings</span> with regards to the
        information that they shared and/or collect in{" "}
        <span class="highlighted-word">CROWZFX</span>.
      </p>

      <h2>8. Consent</h2>
      <p>
        By using <span class="highlighted-word">CROWZFX's</span>{" "}
        <span class="highlighted-word">offerings</span>, you hereby consent to
        this Privacy Policy and agree to its{" "}
        <a href="/policies/terms-of-service">Terms of Service</a>.
      </p>

      <div>
        <hr />
        <p>
          &#169; 2022-25 CROWZFX LTD. All rights reserved. A company registered
          in England and Wales with company number 14188096.
        </p>
        <p>
          Registered address: 71-75 Shelton Street, Covent Garden, London, UK,
          WC2H 9JQ.
        </p>
      </div>
    </div>
  );
}

export default PrivacyPolicy;
