import "../../App.css";

function CookiePolicy() {
  return (
    <div class="policy-wrapper">
      <h1 class="policy-header">Cookie Policy</h1>
      <a class="policy-link" href="/">
        &lt;&lt; Return to home page
      </a>

      <h2>A. Definitions</h2>
      <p>
        The following definitions will be referred to throughout this document
        and mean the following:
      </p>
      <ul>
        <li>
          '<span class="highlighted-word">Offerings</span>' - Refers to any
          products or services that has been developed, coded, offered, deployed
          by CROWZFX LTD. This includes, but not limited to: websites, sevrices,
          platforms, mobile applications.
        </li>
        <li>
          '<span class="highlighted-word">Company</span>'/'
          <span class="highlighted-word">Business</span>'/'
          <span class="highlighted-word">CROWZFX</span>' - Refers to the
          business of CROWZFX LTD and any trading names, services and/or
          products that is owned and offered.
        </li>
      </ul>

      <h2>1. What Are Cookies</h2>
      <p>
        As is common practice with almost all professional websites this site
        uses cookies, which are tiny files that are downloaded to your computer,
        to improve your experience. This page describes what information they
        gather, how <span class="highlighted-word">CROWZFX</span> uses it and
        why <span class="highlighted-word">CROWZFX</span> sometimes need to
        store these cookies. We will also share how you can prevent these
        cookies from being stored however this may downgrade or 'break' certain
        elements of the sites functionality.
      </p>

      <h2>2. How We Use Cookies</h2>
      <p>
        <span class="highlighted-word">CROWZFX</span> uses cookies for a variety
        of reasons detailed below. Unfortunately in most cases there are no
        industry standard options for disabling cookies without completely
        disabling the functionality and features they add to this site. It is
        recommended that you leave on all cookies if you are not sure whether
        you need them or not in case they are used to provide a service that you
        use.
      </p>

      <h2>3. Disabling Cookies</h2>
      <p>
        You can prevent the setting of cookies by adjusting the settings on your
        browser (see your browser Help for how to do this). Be aware that
        disabling cookies will affect the functionality of the{" "}
        <span class="highlighted-word">offerings</span> provided by{" "}
        <span class="highlighted-word">CROWZFX</span> and many other websites
        that you visit. Disabling cookies will usually result in also disabling
        certain functionality and features of said{" "}
        <span class="highlighted-word">offerings</span>. Therefore it is
        recommended to understand what you are doing when disabling cookies.
      </p>

      <h2>4. The Cookies We Set</h2>
      <ul>
        <li>
          <h3>4.A. Site preferences cookies</h3>
          <p>
            In order to provide you with a great experience within the{" "}
            <span class="highlighted-word">offerings</span> by{" "}
            <span class="highlighted-word">CROWZFX</span>,{" "}
            <span class="highlighted-word">CROWZFX</span> provides the
            functionality to set your preferences for these{" "}
            <span class="highlighted-word">offerings</span> function when you
            use it. In order to remember your preferences{" "}
            <span class="highlighted-word">CROWZFX</span> needs to set cookies
            so that this information can be called whenever you interact with a
            page is affected by your preferences.
          </p>
        </li>
      </ul>

      <h2>5. Google DoubleClick DART Cookie</h2>
      <p>
        Google is one of <span class="highlighted-word">CROWZFX's</span>{" "}
        third-party vendors within our{" "}
        <span class="highlighted-word">offerings</span>. It also uses cookies,
        known as DART cookies, to serve ads to our site visitors based upon
        their visit and other sites on the internet. However, visitors may
        choose to decline the use of DART cookies by visiting the Google ad and
        content network Privacy Policy at the following URL{" "}
        <a href="https://policies.google.com/technologies/ads">
          Google Ads Policy
        </a>
        .
      </p>

      <h2>6. Third Party Cookies</h2>
      <p>
        In some special cases <span class="highlighted-word">CROWZFX</span> also
        uses cookies provided by trusted third parties. The following section
        details which third party cookies you might encounter through{" "}
        <span class="highlighted-word">CROWZFX offerings</span>:
      </p>

      <ul>
        <li>
          <p>
            <span class="highlighted-word">CROWZFX offerings</span> uses Google
            Analytics which is one of the most widespread and trusted analytics
            solution on the web for helping us to understand how you use the{" "}
            <span class="highlighted-word">offerings</span> and ways that we can
            improve your experience. These cookies may track things such as how
            long you spend on or within these{" "}
            <span class="highlighted-word">offerings</span> and the pages that
            you visit so we can continue to produce engaging content.
          </p>
          <p>
            For more information on how Google uses cookies,{" "}
            <a href="https://policies.google.com/technologies/cookies">
              see the official Google Cookies page
            </a>
            .
          </p>
        </li>

        <li>
          <p>
            Third party analytics are used to track and measure usage of the{" "}
            <span class="highlighted-word">offerings</span> so that{" "}
            <span class="highlighted-word">CROWZFX</span> can continue to
            produce engaging content. These cookies may track things such as how
            long you spend on or withing the{" "}
            <span class="highlighted-word">offerings</span> or pages you visit
            which helps us to understand how we can improve the{" "}
            <span class="highlighted-word">offerings</span> to you.
          </p>
        </li>

        <li>
          <p>
            From time to time <span class="highlighted-word">CROWZFX</span>{" "}
            tests new features and make subtle changes to the way that the{" "}
            <span class="highlighted-word">offerings</span> are delivered. When{" "}
            <span class="highlighted-word">CROWZFX</span> is testing new
            features these cookies may be used to ensure that you receive a
            consistent experience whilst on or withing the{" "}
            <span class="highlighted-word">offerings</span> whilst ensuring{" "}
            <span class="highlighted-word">CROWZFX</span> understands which
            optimisations our users appreciate the most.
          </p>
        </li>

        <li>
          <p>
            As <span class="highlighted-word">CROWZFX</span> sells products it
            is important for <span class="highlighted-word">CROWZFX</span> to
            understand statistics about how many of the visitors to our{" "}
            <span class="highlighted-word">offerings</span> actually make a
            purchase and as such this is the kind of data that these cookies
            will track. This is important to you as it means that we can
            accurately make business predictions that allow us to monitor our
            advertising and product costs to ensure the best possible price.
          </p>
        </li>

        <li>
          <p>
            The Google AdSense service we use to serve advertising uses a
            DoubleClick cookie to serve more relevant ads across the web and
            limit the number of times that a given ad is shown to you.
          </p>
          <p>
            For more information on how Google uses cookies,{" "}
            <a href="https://policies.google.com/technologies/cookies">
              see the official Google Cookies page
            </a>
            .
          </p>
        </li>

        <li>
          <p>
            <span class="highlighted-word">CROWZFX</span> uses adverts to offset
            the costs of running, devloping and implementing our{" "}
            <span class="highlighted-word">offerings</span> and provide funding
            for further development. The behavioural advertising cookies used by
            these <span class="highlighted-word">offerings</span> are designed
            to ensure that we provide you with the most relevant adverts where
            possible by anonymously tracking your interests and presenting
            similar things that may be of interest.
          </p>
        </li>
      </ul>

      <h2>7. More Information</h2>
      <p>
        If you are still looking for more information then you can contact us
        through one of our preferred contact method of{" "}
        <a href="mailto:contact@crowzfx.co.uk">contact@crowzfx.co.uk</a>.
      </p>

      <div>
        <hr />
        <p>
          &#169; 2022-25 CROWZFX LTD. All rights reserved. A company registered
          in England and Wales with company number 14188096.
        </p>
        <p>
          Registered address: 71-75 Shelton Street, Covent Garden, London, UK,
          WC2H 9JQ.
        </p>
      </div>
    </div>
  );
}

export default CookiePolicy;
